import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'splitObject'
})
export class SplitObjectPipe implements PipeTransform {
  transform(obj: any): any[][] {
    if (typeof obj !== 'object' || obj === null) {
      return [[], []];
    }

    const entries = Object.entries(obj);
    const middleIndex = Math.ceil(entries.length / 2);
    return [entries.slice(0, middleIndex), entries.slice(middleIndex)];
  }
}
