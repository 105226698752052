import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { Buffer } from 'buffer';
import { DialogService } from '../dialog/dialog.service';
import { CryptService } from '../crypt/crypt.service';
import Swal from 'sweetalert2';
import * as FileSaver from 'file-saver';

@Injectable({
  providedIn: 'root',
})
export class CommonService {
  constructor(
    private http: HttpClient,
    private dialogService: DialogService,
    private cryptService: CryptService
  ) { }

  parseParam(json) {
    return Object.keys(json)
      .map((v) => {
        if (json[v] != null) {
          return v + '=' + encodeURIComponent(json[v]);
        }
      })
      .filter((a) => a)
      .join('&');
  }

  formatTimeToDate(time: Date): Date {
    if (time) {
      const date = new Date(time);
      return new Date(date.getFullYear(), date.getMonth(), date.getDate());
    } else {
      return null;
    }
  }

  convertNgbDateStructToDate(date: NgbDateStruct): Date {
    if (date) {
      return new Date(date.year, date.month - 1, date.day);
    } else {
      return null;
    }
  }

  convertStrToDate(date: string) {
    if (!date) {
      return '';
    }

    const times = date.split('/');
    if (times.length > 0) {
      return new Date(`${times[2]}-${times[1]}-${times[0]}`);
    }

    return '';
  }

  convertDateToString(date: Date): string {
    if (!date) {
      return '';
    }
    if (typeof (date) === 'string') {
      return date;
    }

    const month = date.getMonth() + 1;
    const day = date.getDate();
    const strmonth = month >= 1 && month <= 9 ? '0' + month : month;
    const strday = day >= 1 && day <= 9 ? '0' + day : day;
    return strday + '/' + strmonth + '/' + date.getFullYear();
  }

  getToday() {
    const now = new Date();
    const year = now.getFullYear();
    const month = now.getMonth();
    const day = now.getDate();
    return new Date(year, month, day);
  }

  /**
   *
   * @param obj return a object copy
   */
  deepcopy(obj) {
    return JSON.parse(JSON.stringify(obj));
  }

  dynamicSort(property) {
    let sortOrder = 1;

    if (property[0] === '-') {
      sortOrder = -1;
      property = property.substr(1);
    }

    return (a, b) => {
      if (sortOrder === -1) {
        return b[property].localeCompare(a[property]);
      } else {
        return a[property].localeCompare(b[property]);
      }
    };
  }
  distinctBy(arr, key) {
    const hash = {};
    arr = arr.reduce((item, next) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      hash[next[key]] ? '' : (hash[next[key]] = true && item.push(next));
      return item;
    }, []);
    console.log(arr);
    return arr;
  }
  setJsonValue(key: string, value: any) {
    if (!value && value !== 0 && value !== false) {
      value = '';
    } else {
      value = value.toString();
    }
    this.cryptService.secureStorage.setItem(key, value);
  }
  // Get the json value from local storage
  getJsonValue(key: string) {
    return this.cryptService.secureStorage.getItem(key);
  }
  removeItem(key: string) {
    return this.cryptService.secureStorage.removeItem(key);
  }
  getMonthName (monthNumber){
    switch (monthNumber){
    case 1:
      return 'January';
      break;
    case 2:
      return 'February';
      break;
    case 3:
      return 'March';
      break;
    case 4:
      return 'April';
      break;
    case 5:
      return 'May';
      break;
    case 6:
      return 'June';
      break;
    case 7:
      return 'July';
      break;
    case 8:
      return 'August';
      break;
    case 9:
      return 'September';
      break;
    case 10:
      return 'October';
      break;
    case 11:
      return 'November';
      break;
    case 12:
      return 'December';
      break;
    }
  }
  // Clear the local storage
  clearToken() {
    const rememberMe = this.getJsonValue('rememberMe');
    const cognitoKey = this.getJsonValue('cognito_key');
    const expiresAt = this.getJsonValue('cognito_expires_at');
    const result = this.cryptService.secureStorage.clear();
    if (rememberMe) {
      this.setJsonValue('rememberMe', rememberMe);
    }

    if (cognitoKey) {
      this.setJsonValue('cognito_key', cognitoKey);
      this.setJsonValue('cognito_expires_at', expiresAt);
    }

    return result;
  }

  paramsEncode(json) {
    let str = json;
    if (typeof json == 'object') {
      str = this.parseParam(json);
      str = encodeURIComponent(str);
      return new Buffer(str).toString('base64');
    } else {
      str = encodeURIComponent(str);
      return '_params=' + new Buffer(str).toString('base64');
    }
  }

  paramsDecode(paramsEncode) {
    // eslint-disable-next-line @typescript-eslint/dot-notation
    let paramsStr = paramsEncode['_params'];
    if (!paramsStr) {
      return paramsEncode;
    }
    const params = {};

    paramsStr = decodeURIComponent(new Buffer(paramsStr, 'base64').toString());

    const paramsArray = paramsStr.split('&');

    // eslint-disable-next-line @typescript-eslint/prefer-for-of
    for (let i = 0; i < paramsArray.length; i++) {
      const item = paramsArray[i].split('=');
      params[item[0]] = decodeURIComponent(item[1]);
    }
    return params;
  }
  //获取服务器图片资源
  async getImg(path) {
    return new Promise((resolve, reject) => {
      this.http.post('/Resource/read-portraits?path=' + path, '').subscribe(
        (data) => {
          const flag = this.dialogService.getErrorCode(data);
          if (flag) {
            let result: any = {};
            result = data;
            resolve(result);
          }
        },
        (error) => {
          resolve(error.message);
        }
      );
    });
  }

  //获取服务器签名文件资源
  async getSignature(path) {
    return new Promise((resolve, reject) => {
      this.http.post('/Resource/read-sigrate?path=' + path, '').subscribe(
        (data) => {
          const flag = this.dialogService.getErrorCode(data);
          if (flag) {
            let result: any = {};
            result = data;
            resolve(result);
          }
        },
        (error) => {
          resolve(error.message);
        }
      );
    });
  }
  async getcover(path) {
    return new Promise((resolve, reject) => {
      this.http.post('/Resource/read-cover?path=' + path, '').subscribe(
        (data) => {
          const flag = this.dialogService.getErrorCode(data);
          if (flag) {
            let result: any = {};
            result = data;
            resolve(result);
          }
        },
        (error) => {
          resolve(error.message);
        }
      );
    });
  }
  async getUwLogo(path) {
    return new Promise((resolve, reject) => {
      this.http.post('/Resource/read-uw-logo?path=' + path, '').subscribe(
        (data) => {
          const flag = this.dialogService.getErrorCode(data);
          if (flag) {
            let result: any = {};
            result = data;
            resolve(result);
          }
        },
        (error) => {
          resolve(error.message);
        }
      );
    });
  }
  async getFlyckeLogo(path) {
    return new Promise((resolve, reject) => {
      this.http.post('/Resource/read-logo?path=' + path, '').subscribe(
        (data) => {
          const flag = this.dialogService.getErrorCode(data);
          if (flag) {
            let result: any = {};
            result = data;
            resolve(result);
          }
        },
        (error) => {
          resolve(error.message);
        }
      );
    });
  }
  openNewWindow(target: string, name?: string) {
    if (!target) {
      return false;
    }
    name = name ? name : 'new_window';
    const option = 'resizable=yes,scrollbars=yes';
    const nw = window.open(target, name, option);
    nw.moveTo(0, 0);
    nw.resizeTo(nw.screen.availWidth, nw.screen.availHeight);
  }

  openNewWindow2(target: string, name?: string) {
    if (!target) {
      return false;
    }
    name = name ? name : 'new_window2';
    const iWidth = 1024;
    const iHeight = 768;
    const iTop = (window.screen.height - 30 - iHeight) / 2;
    const iLeft = (window.screen.width - 10 - iWidth) / 2;
    window.open(target, '_blank', 'height=' + iHeight + ',innerHeight=' + iHeight + ',width=' + iWidth + ',innerWidth=' + iWidth + ',top=' + iTop + ',left=' + iLeft + ',toolbar=no,menubar=no,scrollbars=auto,resizable=no,location=no,status=no');
  }

  setCognito(cognitoKey: string) {
    let expiresAt = new Date().getTime();
    expiresAt += 30 * 60 * 1000;
    this.setJsonValue('cognito_key', cognitoKey);
    this.setJsonValue('cognito_expires_at', expiresAt);
  }

  checkCognito() {
    const cognitoKey = this.getJsonValue('cognito_key');
    let expiresAt = this.getJsonValue('cognito_expires_at');
    expiresAt = expiresAt ? parseInt(expiresAt, 10) : 0;
    const timer = new Date().getTime();
    if (!cognitoKey || expiresAt < timer) {
      this.clearCognito();
      return false;
    }
    return cognitoKey;
  }

  clearCognito() {
    this.removeItem('cognito_key');
    this.removeItem('cognito_expires_at');
  }

  closeWindow() {
    if (navigator.userAgent.indexOf('Firefox') !== -1 || navigator.userAgent.indexOf('Chrome') !== -1) {
      location.href = 'about:blank';
      window.close();
    } else {
      window.opener = null;
      window.open('', '_self');
      window.close();
    }
  }

  getPolicyPeriod(addAll = false) {
    return new Promise((resolve, reject) => {
      this.http.get<any>('/accountant/policy-period').subscribe(data => {
        const flag = this.dialogService.getErrorCode(data); //judge data code
        if (flag) {
          const policyOption = new Array();
          if (addAll) {
            policyOption.push({ value: '', label: 'Select One' });
          }
          for (const item of data) {
            policyOption.push(item);
          }
          resolve(policyOption);
        }
      }, error => {
        resolve(error.message);
      });
    });

  }

  getUWPolicyPeriod(sysId) {
    return new Promise((resolve, reject) => {
      this.http.get<any>('/accountant/policy-period?sysId=' + sysId).subscribe(data => {
        const flag = this.dialogService.getErrorCode(data); //judge data code
        if (flag) {
          const policyOption = new Array();
          //policyOption.push({ value: 'all', label: 'Select One' });
          for (const item of data) {
            policyOption.push(item);
          }
          resolve(policyOption);
        }
      }, error => {
        resolve(error.message);
      });
    });
  }

  checkUploadFile(file, maxSize, types) {
    //file size limit
    if (file.size > maxSize) {
      Swal.fire({
        text: 'The maximum file size allowed is ' + maxSize / (1024 * 1024) + ' MB!',
        icon: 'warning',
      });
      return false;
    }
    //file type limit
    const lastType = types[types.length - 1];
    //types.pop();
    const type = file.name.split('.').slice(-1)[0].toLowerCase(); //get type from filename . last one
    if (types.indexOf(type) === -1) {
      Swal.fire({
        text: 'The file types allowed are ' + types.join(', ') + ' and ' + lastType + '.',
        icon: 'warning',
      });
      return false;
    }
    return true;
  }

  downloadFile(response: HttpResponse<any>) {
    let flag = this.dialogService.getErrorCode(response);
    if (!flag) {
      return false;
    }
    flag = this.dialogService.checkFileError(response.body);
    if (flag) {
      const disposition = response.headers.get('Content-Disposition');
      const filenameRegex = /filename\*=UTF-8([\s\S]+)/;

      const matches = filenameRegex.exec(disposition);
      let filename = '';
      if (matches != null && matches[1]) {
        filename = matches[1].replace(/['"]/g, '');
      }

      if (filename.indexOf('.csv') > -1) {
        // const BOM = '\uFEFF';
        const blob = new Blob([String.fromCharCode(0xfeff), response.body]);
        FileSaver.saveAs(blob, decodeURIComponent(filename));
      } else {
        FileSaver.saveAs(response.body, decodeURIComponent(filename));
      }
    }
  }

  getBrokerAccessLevel() {
    const userAccessLevel = this.getJsonValue('accessLevel');
    if (userAccessLevel) {
      const alArr = userAccessLevel.split(',');
      return { accessLevelOne: alArr[0], accessLevelTwo: alArr[1] };
    }
    return null;
  }

  getBrokerAccessLevelArr() {
    const userAccessLevel = this.getJsonValue('accessLevel');
    if (userAccessLevel) {
      return userAccessLevel.split(',');
    }
    return [];
  }
}
