export class BrokerMenu {
  public static items = [
    {
      id: 'navigation',
      title: 'Navigation',
      type: 'group',
      icon: 'feather icon-align-left',
      children: [
        {
          id: 'my-workbook',
          title: 'My Workbook',
          type: 'item',
          url: '/broker/workbook',
          classes: 'nav-item',
          icon: 'feather icon-home',
        },
        {
          id: 'prp-dashboard',
          title: 'PRP Dashboard ',
          type: 'item',
          url: '/broker/prp-dashboard',
          classes: 'nav-item',
          icon: 'feather icon-layers',
        },
        {
          id: 'broker-accountant-summary-detail',
          title: 'Accountant Summary',
          type: 'item',
          hidden: true,
          url: '/broker/reports/broker-accountant-summary-detail',
          urlStartWith: `/broker/reports/broker-accountant-summary-detail`,
        },
        {
          id: 'admin',
          title: 'Admin',
          type: 'collapse',
          icon: 'feather icon-settings',
          hidden: true,
          children: [
            {
              id: 'add-accout',
              title: 'Add Account',
              type: 'item',
              url: '/broker/admin/add-account',
            },
            {
              id: 'list-accout',
              title: 'Edit Account',
              type: 'item',
              url: '/broker/admin/account-list',
            },
            {
              id: 'edit-accout',
              title: 'Edit Account',
              type: 'item',
              url: '/broker/admin/edit-account',
              hidden: true,
              urlStartWith: `/broker/admin/edit-account/`,
            },
            {
              id: 'assign-accout',
              title: 'Assign Account',
              type: 'item',
              url: '/broker/admin/assign-account',
              hidden: true,
              urlStartWith: `/broker/admin/assign-account/`,
            },
            {
              id: 'edit-company',
              title: 'Edit Company',
              type: 'item',
              url: '/broker/admin/edit-company',
            },
            {
              id: 'template-upload',
              title: 'Template Upload',
              type: 'item',
              url: '/broker/admin/template-upload',
            },
            {
              id: 'underpayment-email-templates',
              title: 'DEFT Over/Underpayment Email Templates',
              type: 'item',
              url: '/broker/admin/underpayment-email-templates',
            },
            {
              id: 'upload-data',
              title: 'Upload Data',
              type: 'item',
              url: '/broker/admin/upload-data',
            },
            {
              id: 'policy-wording-generation',
              title: 'Policy Schedule & Wordings Generation',
              type: 'item',
              url: '/broker/admin/policy-wording-generation',
            },
            {
              id: '',
              title: 'Welcome Messages / Notifications',
              type: 'item',
              url: '/broker/admin/notifications',
            },
            {
              id: '',
              title: 'Add/Edit Associates',
              type: 'item',
              url: '/broker/admin/edit-associate',
            },
            {
              id: '',
              title: 'Migrate Accountant',
              type: 'item',
              url: '/broker/admin/migrate-accountant',
            },
            {
              id: '',
              title: 'Reviewed Upload Data',
              type: 'item',
              url: '/broker/admin/reviewed-upload-data',
            },
            {
              id: '',
              title: 'Invoice Refund Editor',
              type: 'item',
              url: '/broker/admin/invoice-refund-editor',
            },
            {
              id: '',
              title: 'AI Broker App Logs',
              type: 'item',
              url: '/broker/admin/broker-app-logs',
            },
            {
              id: '',
              title: 'Assign Multiple Invoice Templates',
              type: 'item',
              url: '/broker/admin/assign-multiple-invoice-templates',
            },
            {
              id: '',
              title: 'Rollback Accountant',
              type: 'item',
              url: '/broker/admin/rollback-accountant',
            },
            {
              id: '',
              title: 'Invoice Amount Editor',
              type: 'item',
              url: '/broker/admin/invoice-amount-editor',
            },
            {
              id: '',
              title: 'Transaction Logs Read Only',
              type: 'item',
              url: '/broker/admin/',
            },
            {
              id: '',
              title: 'Change Policy Date',
              type: 'item',
              url: '/broker/admin/',
            },
            {
              id: '',
              title: 'List Sub-Intermediaries',
              type: 'item',
              url: '/broker/admin/',
            },
            {
              id: '',
              title: 'Ironman App Settings',
              type: 'item',
              url: '/broker/admin/',
            },
            {
              id: '',
              title: 'Ironman App Usage Report',
              type: 'item',
              url: '/broker/admin/',
            },
            {
              id: '',
              title: 'Create Sub-Intermediary',
              type: 'item',
              url: '/broker/admin/',
            },
            {
              id: '',
              title: 'Custom Field Sections',
              type: 'item',
              url: '/broker/admin/',
            },
            {
              id: '',
              title: 'Custom Fields',
              type: 'item',
              url: '/broker/admin/',
            },
            {
              id: '',
              title: 'PI Invoice SMSF',
              type: 'item',
              url: '/broker/admin/',
            },
            {
              id: '',
              title: 'PI Invoice Templates',
              type: 'item',
              url: '/broker/admin/',
            },
            {
              id: '',
              title: 'PI NSW Declaration',
              type: 'item',
              url: '/broker/admin/',
            },
            {
              id: '',
              title: 'PI Receipt Templates',
              type: 'item',
              url: '/broker/admin/',
            },
            {
              id: '',
              title: 'PI Email #1 Templates',
              type: 'item',
              url: '/broker/admin/',
            },
            {
              id: '',
              title: 'PI Email #2 Templates',
              type: 'item',
              url: '/broker/admin/',
            },
            {
              id: '',
              title: 'PI Email #2 Referral/Decline Templates',
              type: 'item',
              url: '/broker/admin/',
            },
            {
              id: '',
              title: 'PI Email #3 Templates',
              type: 'item',
              url: '/broker/admin/',
            },
            {
              id: '',
              title: 'PI Email #4 Templates',
              type: 'item',
              url: '/broker/admin/',
            },
            {
              id: '',
              title: 'PI Email #5 Templates',
              type: 'item',
              url: '/broker/admin/',
            },
            {
              id: '',
              title: 'PI Email #6 Templates',
              type: 'item',
              url: '/broker/admin/',
            },
            {
              id: '',
              title: 'PI Email #9 Templates',
              type: 'item',
              url: '/broker/admin/',
            },
            {
              id: '',
              title: 'PI Email #7 Templates',
              type: 'item',
              url: '/broker/admin/',
            },
            {
              id: '',
              title: 'Tax Agent Email #6 Templates',
              type: 'item',
              url: '/broker/admin/',
            },
            {
              id: '',
              title: 'BAS Agent Email #6 Templates',
              type: 'item',
              url: '/broker/admin/',
            },
            {
              id: '',
              title: 'PI Email #8 Templates',
              type: 'item',
              url: '/broker/admin/',
            },
            {
              id: '',
              title: 'PI Email Save Application',
              type: 'item',
              url: '/broker/admin/',
            },
            {
              id: '',
              title: 'PI Quote Report SMSF',
              type: 'item',
              url: '/broker/admin/',
            },
            {
              id: '',
              title: 'PI Schedule of Insurance SMSF',
              type: 'item',
              url: '/broker/admin/',
            },
            {
              id: '',
              title: 'PI Schedule of Insurance',
              type: 'item',
              url: '/broker/admin/',
            },
            {
              id: '',
              title: 'PI Certificate of Currency',
              type: 'item',
              url: '/broker/admin/',
            },
            {
              id: '',
              title: 'PI Quote Report',
              type: 'item',
              url: '/broker/admin/',
            },
            {
              id: '',
              title: 'PI Binding Instructions',
              type: 'item',
              url: '/broker/admin/',
            },
            {
              id: '',
              title: 'PI Policy Wording File',
              type: 'item',
              url: '/broker/admin/',
            },
            {
              id: '',
              title: 'Change Rate Set',
              type: 'item',
              url: '/broker/admin/',
            },
            {
              id: '',
              title: 'Tax Agent BindingInstructions',
              type: 'item',
              url: '/broker/admin/',
            },
            {
              id: '',
              title: 'Bas Agent BindingInstructions',
              type: 'item',
              url: '/broker/admin/',
            },
            {
              id: '',
              title: 'Email Queue',
              type: 'item',
              url: '/broker/admin/',
            },
            {
              id: '',
              title: 'SendGrid Monitor Read Only',
              type: 'item',
              url: '/broker/admin/',
            },
            {
              id: '',
              title: 'BAS Agent Email #1',
              type: 'item',
              url: '/broker/admin/',
            },
            {
              id: '',
              title: 'BAS Agent Email #2',
              type: 'item',
              url: '/broker/admin/',
            },
            {
              id: '',
              title: 'BAS Agent Email #3',
              type: 'item',
              url: '/broker/admin/',
            },
            {
              id: '',
              title: 'BAS Agent Email #4',
              type: 'item',
              url: '/broker/admin/',
            },
            {
              id: '',
              title: 'BAS Agent Email #5',
              type: 'item',
              url: '/broker/admin/',
            },
            {
              id: '',
              title: 'Upload User Manual',
              type: 'item',
              url: '/broker/admin/',
            },
            {
              id: '',
              title: 'BAS Agent Invoice Templates',
              type: 'item',
              url: '/broker/admin/',
            },
            {
              id: '',
              title: 'BAS Agent Policy Wording File',
              type: 'item',
              url: '/broker/admin/',
            },
            {
              id: '',
              title: 'BAS Agent Quote Report',
              type: 'item',
              url: '/broker/admin/',
            },
            {
              id: '',
              title: 'BAS Agent Receipt Templates',
              type: 'item',
              url: '/broker/admin/',
            },
            {
              id: '',
              title: 'Indo Dashboard Manager',
              type: 'item',
              url: '/broker/admin/',
            },
            {
              id: '',
              title: 'BAS Agent Save Email',
              type: 'item',
              url: '/broker/admin/',
            },
            {
              id: '',
              title: 'Tax Agent Email #1',
              type: 'item',
              url: '/broker/admin/',
            },
            {
              id: '',
              title: 'Cyber Insurance Email #1',
              type: 'item',
              url: '/broker/admin/',
            },
            {
              id: '',
              title: 'ABN Cyber Insurance Templates',
              type: 'item',
              url: '/broker/admin/',
            },
            {
              id: '',
              title: 'Cyber Insurance Email #2',
              type: 'item',
              url: '/broker/admin/',
            },
            {
              id: '',
              title: 'Cyber Insurance Email #3',
              type: 'item',
              url: '/broker/admin/',
            },
            {
              id: '',
              title: 'Cyber Insurance Email Save',
              type: 'item',
              url: '/broker/admin/',
            },
            {
              id: '',
              title: 'Cyber Insurance Quote Report',
              type: 'item',
              url: '/broker/admin/',
            },
            {
              id: '',
              title: 'Cyber Insurance Policy Wording File',
              type: 'item',
              url: '/broker/admin/',
            },
            {
              id: '',
              title: 'Tax Agent Policy Wording File',
              type: 'item',
              url: '/broker/admin/',
            },
            {
              id: '',
              title: 'BAS Agent Email #2 Referral / Decline Templates',
              type: 'item',
              url: '/broker/admin/',
            },
            {
              id: '',
              title: 'Tax Agent Public LiabilityCertificate of Currency',
              type: 'item',
              url: '/broker/admin/',
            },
            {
              id: '',
              title: 'Tax Agent Email #2 Referral / Decline Templates',
              type: 'item',
              url: '/broker/admin/',
            },
            {
              id: '',
              title: 'Tax Agent Quote Report',
              type: 'item',
              url: '/broker/admin/',
            },
            {
              id: '',
              title: 'Tax Agent PublicLiability Receipt',
              type: 'item',
              url: '/broker/admin/',
            },
            {
              id: '',
              title: 'BAS Agent PublicLiability Receipt',
              type: 'item',
              url: '/broker/admin/',
            },
            {
              id: '',
              title: 'BAS Agent PublicLiability Invoice',
              type: 'item',
              url: '/broker/admin/',
            },
            {
              id: '',
              title: 'Tax Agent PublicLiability Invoice',
              type: 'item',
              url: '/broker/admin/',
            },
            {
              id: '',
              title: 'BAS Agent PublicLiability Quote Report',
              type: 'item',
              url: '/broker/admin/',
            },
            {
              id: '',
              title: 'Tax Agent PublicLiability Quote Report',
              type: 'item',
              url: '/broker/admin/',
            },
            {
              id: '',
              title: 'Tax Agent Public LiabilitySchedule of Insurance',
              type: 'item',
              url: '/broker/admin/',
            },
            {
              id: '',
              title: 'BAS Agent Public LiabilityCertificate of Currency',
              type: 'item',
              url: '/broker/admin/',
            },
            {
              id: '',
              title: 'BAS Agent Public LiabilitySchedule of Insurance',
              type: 'item',
              url: '/broker/admin/',
            },
            {
              id: '',
              title: 'Tax Agent Email #2',
              type: 'item',
              url: '/broker/admin/',
            },
            {
              id: '',
              title: 'Tax Agent Receipt Templates',
              type: 'item',
              url: '/broker/admin/',
            },
            {
              id: '',
              title: 'BAS Agent Certificate ofCurrency',
              type: 'item',
              url: '/broker/admin/',
            },
            {
              id: '',
              title: 'Tax Agent Save Email',
              type: 'item',
              url: '/broker/admin/',
            },
            {
              id: '',
              title: 'Tax Agent Certificate ofCurrency',
              type: 'item',
              url: '/broker/admin/',
            },
            {
              id: '',
              title: 'Tax Agent Email #3',
              type: 'item',
              url: '/broker/admin/',
            },
            {
              id: '',
              title: 'BAS Agent Schedule ofInsurance',
              type: 'item',
              url: '/broker/admin/',
            },
            {
              id: '',
              title: 'Tax Agent Schedule ofInsurance',
              type: 'item',
              url: '/broker/admin/',
            },
            {
              id: '',
              title: 'Tax Agent Email #4',
              type: 'item',
              url: '/broker/admin/',
            },
            {
              id: '',
              title: 'Tax Agent Email #5',
              type: 'item',
              url: '/broker/admin/',
            },
            {
              id: '',
              title: 'Tax Agent Invoice Templates',
              type: 'item',
              url: '/broker/admin/',
            },
            {
              id: '',
              title: 'DRN Setting',
              type: 'item',
              url: '/broker/admin/',
            },
            {
              id: '',
              title: 'AI Invoice Templates',
              type: 'item',
              url: '/broker/admin/',
            },
            {
              id: '',
              title: 'Custom Field Sorting',
              type: 'item',
              url: '/broker/admin/',
            },
            {
              id: '',
              title: 'Postage Templates',
              type: 'item',
              url: '/broker/admin/',
            },
            {
              id: '',
              title: 'Page Logs  Read Only',
              type: 'item',
              url: '/broker/admin/',
            },
            {
              id: '',
              title: 'AI Invoice Email Templates',
              type: 'item',
              url: '/broker/admin/',
            },
            {
              id: '',
              title: 'Audit ShieldContact Entries',
              type: 'item',
              url: '/broker/admin/',
            },
            {
              id: '',
              title: 'Eway Surcharge',
              type: 'item',
              url: '/broker/admin/',
            },
            {
              id: '',
              title: 'Statement of Account Templates',
              type: 'item',
              url: '/broker/admin/',
            },
            {
              id: '',
              title: 'Statement of AccountEmail Templates',
              type: 'item',
              url: '/broker/admin/',
            },
            {
              id: '',
              title: 'Upload PAY File',
              type: 'item',
              url: '/broker/admin/',
            },
            {
              id: '',
              title: 'Broker State Rate',
              type: 'item',
              url: '/broker/admin/',
            },
            {
              id: '',
              title: 'AI Receipt Templates',
              type: 'item',
              url: '/broker/admin/',
            },
          ],
        },
        {
          id: 'accountant',
          title: 'Accountant',
          type: 'collapse',
          hidden: true,
          icon: 'feather icon-grid',
          children: [
            {
              id: 'search-accountant-list',
              title: 'Accountant List',
              type: 'item',
              hidden: true,
              url: '/broker/accountant/search-accountant-list',
              urlStartWith: `/broker/accountant/search-accountant-list`,
            },
            {
              id: '',
              title: 'List Accountant',
              type: 'item',
              url: '/broker/accountant/',
            },
            {
              id: '',
              title: 'Search Accountant',
              type: 'item',
              url: '/broker/accountant/',
            },
            {
              id: '',
              title: 'Update Documents',
              type: 'item',
              url: '/broker/accountant/',
            },
            {
              id: '',
              title: 'Cancel Payment',
              type: 'item',
              url: '/broker/accountant/',
            },
            {
              id: '',
              title: 'Underwriting Notes',
              type: 'item',
              url: '/broker/accountant/',
            },
            {
              id: '',
              title: 'Group of Accountants',
              type: 'item',
              url: '/broker/accountant/',
            },
            {
              id: '',
              title: 'Upload AI Special Invoices',
              type: 'item',
              url: '/broker/accountant/',
            },
            {
              id: '',
              title: 'Upload AI Invoices',
              type: 'item',
              url: '/broker/accountant/',
            },
            {
              id: '',
              title: 'Search AI Invoices',
              type: 'item',
              url: '/broker/accountant/',
            },
            {
              id: '',
              title: 'Search PI Invoice',
              type: 'item',
              url: '/broker/accountant/',
            },
            {
              id: '',
              title: 'Search AI Invoice(NEW)',
              type: 'item',
              url: '/broker/accountant/',
            },
            {
              id: '',
              title: 'Upload AI Payment',
              type: 'item',
              url: '/broker/accountant/',
            },
            {
              id: '',
              title: 'Outstanding AI Invoices',
              type: 'item',
              url: '/broker/accountant/',
            },
            {
              id: '',
              title: 'Marketing Resources Orders',
              type: 'item',
              url: '/broker/accountant/',
            },
            {
              id: '',
              title: 'Marketing Download Resources',
              type: 'item',
              url: '/broker/accountant/',
            },
            {
              id: '',
              title: 'Marketing Order Stock Resources',
              type: 'item',
              url: '/broker/accountant/',
            },
            {
              id: '',
              title: 'Search DRN',
              type: 'item',
              url: '/broker/accountant/',
            },
            {
              id: '',
              title: 'AI Receipt Templates',
              type: 'item',
              url: '/broker/accountant/',
            },
          ],
        },
        {
          id: 'pi',
          title: 'PI',
          type: 'collapse',
          icon: 'feather icon-list',
          children: [
            {
              id: '',
              title: 'Quote Rates',
              type: 'item',
              url: '/broker/pi/',
            },
            {
              id: '',
              title: 'Cyber Quote Rates',
              type: 'item',
              url: '/broker/pi/',
            },
            {
              id: '',
              title: 'PI Templates',
              type: 'item',
              url: '/broker/pi/',
            },
            {
              id: '',
              title: 'Tax Agent Templates',
              type: 'item',
              url: '/broker/pi/',
            },
            {
              id: '',
              title: 'Referral/Decline Triggers',
              type: 'item',
              url: '/broker/pi/',
            },
            {
              id: '',
              title: 'Cyber Insurance Templates',
              type: 'item',
              url: '/broker/pi/',
            },
            {
              id: '',
              title: 'BAS Agent Templates',
              type: 'item',
              url: '/broker/pi/',
            },
            {
              id: '',
              title: 'PI Renewals',
              type: 'item',
              url: '/broker/pi/',
            },
            {
              id: '',
              title: 'ABN Cyber Insurance Templates',
              type: 'item',
              url: '/broker/pi/',
            },
            {
              id: '',
              title: 'PI Binding Email',
              type: 'item',
              url: '/broker/pi/',
            },
            {
              id: '',
              title: 'BAS Agent Binding Email',
              type: 'item',
              url: '/broker/pi/',
            },
            {
              id: '',
              title: 'Tax Agent Binding Email',
              type: 'item',
              url: '/broker/pi/',
            },
          ],
        },
        {
          id: 'lead',
          title: 'Lead',
          type: 'collapse',
          icon: 'feather icon-align-justify',
          children: [
            {
              id: '',
              title: 'List Leads',
              type: 'item',
              url: '/broker/lead/',
            },
            {
              id: '',
              title: 'Create',
              type: 'item',
              url: '/broker/lead/',
            },
            {
              id: '',
              title: 'Search Leads',
              type: 'item',
              url: '/broker/lead/',
            },
            {
              id: '',
              title: 'Dashboard Management',
              type: 'item',
              url: '/broker/lead/',
            },
          ],
        },
        {
          id: 'claims',
          title: 'Claims',
          type: 'collapse',
          icon: 'feather icon-credit-card',
          children: [
            {
              id: '',
              title: 'List Claims',
              type: 'item',
              url: '/broker/lead/',
            },
            {
              id: '',
              title: 'Search Claims',
              type: 'item',
              url: '/broker/lead/',
            },
          ],
        },
        {
          id: 'contacts',
          title: 'Contacts',
          type: 'item',
          url: '/broker/contacts',
          classes: 'nav-item',
          icon: 'feather icon-users',
        },
        {
          id: 'reports',
          title: 'Reports',
          type: 'collapse',
          icon: 'feather icon-activity',
          children: [
            {
              id: '',
              title: 'Target Reports',
              readonly: true,
              type: 'item',
              url: '/broker/reports/',
            },
            {
              id: '',
              title: 'Closing Broker Export',
              readonly: true,
              type: 'item',
              url: '/broker/reports/',
            },
            {
              id: '',
              title: 'Closing Broker',
              type: 'item',
              url: '/broker/reports/',
            },
            {
              id: '',
              title: 'Closing Broker - Year onYear Summary',
              readonly: true,
              type: 'item',
              url: '/broker/reports/',
            },
            {
              id: '',
              title: 'Accountant Rates',
              readonly: true,
              type: 'item',
              url: '/broker/reports/',
            },
            {
              id: '',
              title: 'Practice Closing Broker',
              readonly: true,
              type: 'item',
              url: '/broker/reports/',
            },
            {
              id: '',
              title: 'Accountant Invoice',
              readonly: true,
              type: 'item',
              url: '/broker/reports/',
            },
            {
              id: '',
              title: 'Renewal Dates',
              readonly: true,
              type: 'item',
              url: '/broker/reports/',
            },
            {
              id: '',
              title: 'All Accountant',
              readonly: true,
              type: 'item',
              url: '/broker/reports/',
            },
            {
              id: '',
              title: 'All Accountant - Loss Ratio',
              readonly: true,
              type: 'item',
              url: '/broker/reports/',
            },
            {
              id: '',
              title: 'Credit Adjustment',
              readonly: true,
              type: 'item',
              url: '/broker/reports/',
            },
            {
              id: '',
              title: 'Client Summary by Rateset',
              readonly: true,
              type: 'item',
              url: '/broker/reports/',
            },
            {
              id: '',
              title: 'Accountant ReconciliationReport',
              type: 'item',
              url: '/broker/reports/',
            },
            {
              id: '',
              title: 'Group ReconciliationReport',
              readonly: true,
              type: 'item',
              url: '/broker/reports/',
            },
            {
              id: '',
              title: 'Accountants By AccountManager',
              readonly: true,
              type: 'item',
              url: '/broker/reports/',
            },
            {
              id: '',
              title: 'Category Report Export',
              readonly: true,
              type: 'item',
              url: '/broker/reports/',
            },
            {
              id: '',
              title: 'Take Up Report',
              readonly: true,
              type: 'item',
              url: '/broker/reports/',
            },
            {
              id: '',
              title: 'Category Report Export (New)',
              readonly: true,
              type: 'item',
              url: '/broker/reports/',
            },
            {
              id: 'accountant-summary',
              title: 'Accountant Summary',
              type: 'item',
              url: '/broker/reports/broker-accountant-summary',
              urlStartWith: `/broker/reports/broker-accountant-summary`
            },
            {
              id: 'broker-accountant-summary-detail',
              title: 'Accountant Summary',
              type: 'item',
              hidden: true,
              url: '/broker/reports/broker-accountant-summary-detail',
              urlStartWith: `/broker/reports/broker-accountant-summary-detail`,
            },
            {
              id: '',
              title: 'Take Up Comparison',
              readonly: true,
              type: 'item',
              url: '/broker/reports/',
            },
            {
              id: '',
              title: 'SendGrid Email Report(Testing)',
              type: 'item',
              url: '/broker/reports/',
            },
            {
              id: '',
              title: 'SendGrid Email Report',
              type: 'item',
              url: '/broker/reports/',
            },
            {
              id: '',
              title: 'Followup Date Report',
              readonly: true,
              type: 'item',
              url: '/broker/reports/',
            },
            {
              id: '',
              title: 'Accountant Contacts',
              readonly: true,
              type: 'item',
              url: '/broker/reports/',
            },
            {
              id: 'paid-by-account-manager',
              title: 'Paid by Account Manager',
              readonly: true,
              type: 'item',
              url: '/broker/reports/paid-by-account-manager',
            },
            {
              id: 'paid-by-account-manager-list',
              title: 'Paid by Account Manager',
              hidden: true,
              type: 'item',
              url: '/broker/reports/paid-by-account-manager-list',
              urlStartWith: '/broker/reports/paid-by-account-manager'
            },
            {
              id: 'paid-by-am-report-pmr',
              title: 'Paid by AM Report (PMR)',
              readonly: true,
              type: 'item',
              url: '/broker/reports/paid-by-am-pmr',
            },
            {
              id: 'paid-by-am-report-pmr-list',
              title: 'Paid by AM Report (PMR)',
              hidden: true,
              type: 'item',
              url: '/broker/reports/paid-by-am-pmr-list',
              urlStartWith: '/broker/reports/paid-by-am-pmr'
            },
            {
              id: '',
              title: 'Policy Periods and Binders',
              type: 'item',
              url: '/broker/reports/',
            },
            {
              id: '',
              title: 'Daily Mailout',
              readonly: true,
              type: 'item',
              url: '/broker/reports/',
            },
            {
              id: '',
              title: 'Take Up Comparison By Month',
              readonly: true,
              type: 'item',
              url: '/broker/reports/',
            },
            {
              id: '',
              title: 'Custom Field Report',
              readonly: true,
              type: 'item',
              url: '/broker/reports/',
            },
            {
              id: 'claim-report-by-claim-type',
              title: 'Claims Report by Claim Type',
              type: 'item',
              url: '/uw/reports/claim-report-by-claim-type',
            },
            {
              id: '',
              title: 'Client Take Up Report and Ranking',
              readonly: true,
              type: 'item',
              url: '/broker/reports/',
            },
            {
              id: '',
              title: 'Macquarie Solution Reconciliation Report',
              readonly: true,
              type: 'item',
              url: '/broker/reports/',
            },
            {
              id: '',
              title: 'PI Custom Field Report',
              readonly: true,
              type: 'item',
              url: '/broker/reports/',
            },
          ],
        },
        {
          id: 'rates',
          title: 'Rates',
          type: 'collapse',
          icon: 'feather icon-file-text',
          children: [
            {
              id: '',
              title: 'List Rate Set',
              type: 'item',
              url: '/broker/rates/',
            },
            {
              id: '',
              title: 'Create Rate Set',
              type: 'item',
              url: '/broker/rates/',
            },
            {
              id: '',
              title: 'List Category',
              type: 'item',
              url: '/broker/rates/',
            },
          ],
        },
        {
          id: 'auditLogs',
          title: 'Audit Logs',
          type: 'collapse',
          icon: 'feather icon-alert-triangle',
          children: [
            {
              id: 'auth-logs',
              title: 'Auth Logs',
              type: 'item',
              url: '/broker/logs/auth-logs',
            },
          ],
        },
        {
          id: 'show-admin',
          title: 'Admin',
          type: 'collapse',
          icon: 'feather icon-settings',
          children: [
            {
              id: 'ip-address-labels',
              title: 'IP Address Labels',
              type: 'item',
              url: '/broker/admin/ip-address-labels',
            },
          ],
        },
      ],
    },
  ];
};


export class NZBrokerMenu {
  public static items = [
    {
      id: 'navigation',
      title: 'Navigation',
      type: 'group',
      icon: 'feather icon-align-left',
      children: [
        {
          id: 'auditLogs',
          title: 'Audit Logs',
          type: 'collapse',
          icon: 'feather icon-alert-triangle',
          children: [
            {
              id: 'auth-logs',
              title: 'Auth Logs',
              type: 'item',
              url: '/broker/logs/auth-logs',
            },
          ],
        },
      ],
    },
  ];
};

export class CABrokerMenu {
  public static items = [
    {
      id: 'navigation',
      title: 'Navigation',
      type: 'group',
      icon: 'feather icon-align-left',
      children: [
        {
          id: 'auditLogs',
          title: 'Audit Logs',
          type: 'collapse',
          icon: 'feather icon-alert-triangle',
          children: [
            {
              id: 'auth-logs',
              title: 'Auth Logs',
              type: 'item',
              url: '/broker/logs/auth-logs',
            },
          ],
        },
      ],
    },
  ];
};
