import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AdminComponent } from './theme/layout/admin/admin.component';
import { AuthComponent } from './theme/layout/auth/auth.component';
import { SsoComponent } from './sso/sso.component';
import { PageComponent } from './theme/layout/page/page.component';
import { AuthGuard } from './theme/shared/guards/auth.guard';
import { RoleGuard } from './theme/shared/guards/role.guard';
import { environment } from 'src/environments/environment';

const routes: Routes = [
  {
    path: '',
    component: PageComponent,
    canActivate: [AuthGuard, RoleGuard],
    children: [
      {
        path: '',
        redirectTo: `/${environment.country.toLowerCase()}/uw/home`,
        pathMatch: 'full',
      },
      {
        path: ':region/page',
        loadChildren: () => import('./underwriter/page/page.module').then((m) => m.UWPageModule),
      },
    ],
  },
  {
    path: '',
    component: AdminComponent,
    canActivate: [AuthGuard, RoleGuard],
    children: [
      {
        path: ':region/uw',
        loadChildren: () =>
          import('./underwriter/underwriter.module').then(
            (module) => module.UnderwriterModule
          ),
      },
      {
        path: ':region/broker',
        loadChildren: () =>
          import('./broker/broker.module').then(
            (module) => module.BrokerModule
          ),
      },
    ],
  },
  {
    path: '',
    component: AuthComponent,
    children: [
      {
        path: 'auth',
        loadChildren: () =>
          import('./auth/auth.module').then((m) => m.AuthModule),
      },
    ],
  },
  {
    path: '',
    component: SsoComponent,
    children: [
      {
        path: 'sso',
        loadChildren: () => import('./sso/sso.module').then((m) => m.SsoModule),
      },
    ],
  },
  {
    path: '',
    children: [
      {
        path: 'no-permission',
        loadChildren: () => import('./theme/no-permission/no-permission.module').then(module => module.NoPermissionModule)
      },
    ]
  },
  { path: '**', redirectTo: '' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy', enableTracing: true })],
  exports: [RouterModule],
})
export class AppRoutingModule { }
