export class TraceUrl {
  public static items = [
    '/broker/Broker/get-myworkbook-renewals',
    '/uw/report/accountant-summary-form',
    '/uw/report/accountant-summary',
    '/broker/Report/paid-by-am-report',
    '/broker/Broker/get-summary-details',
    '/broker/Broker/get-address',
    '/Broker/broker/get-templates',
    '/broker/Broker/get-product-options',
    '/broker/Broker/get-followup-dates',
    '/broker/Broker/view-previous-custom-fields',
    '/broker/Mailout/get-mailout-datas',
    '/broker/Broker/get-contacts',
    '/broker/Broker/get-accountant-history'
  ];
};
