import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CalcDateDurationPipe } from './calc-date-duration/calc-date-duration.pipe';
import { ConvertDateFormatPipe } from './convert-date-format/convert-date-format.pipe';
import { HtmlPipe } from './html/html.pipe';
import { DateFormatPipe } from './date-format/date-format.pipe';
import { DatetimeFormatPipe } from './datetime-format/datetime-format.pipe';
import { CurrencyFormatPipe } from './currency-format/currency-format.pipe';
import { EmailAddressTransformPipe } from './email-address-transform/email-address-transform.pipe';
import { SplitObjectPipe } from './split-object/split-object.pipe';

@NgModule({
  declarations: [CalcDateDurationPipe, ConvertDateFormatPipe, HtmlPipe, DateFormatPipe, DatetimeFormatPipe, CurrencyFormatPipe, EmailAddressTransformPipe, SplitObjectPipe],
  exports: [
    CalcDateDurationPipe,
    ConvertDateFormatPipe,
    HtmlPipe,
    DateFormatPipe,
    DatetimeFormatPipe,
    CurrencyFormatPipe,
    EmailAddressTransformPipe,
    SplitObjectPipe
  ],
  imports: [
    CommonModule
  ]
})
export class PipeModule { }
