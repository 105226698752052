import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'currencyFormat'
})
export class CurrencyFormatPipe implements PipeTransform {

  constructor() {
  }

  transform(value: any): string {
    if (value) {
      try {
        const numberValue = Number(value);
        return '$' + numberValue.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      }
      catch {
        return 'Error';
      }
    } else {
      return '$0.00';
    }
  }

}
