const backendHost = 'https://app-win-au.stg.tai-anchorbuild.com';
const payaiUrl = 'https://payai-com-au.stg.tai-anchorbuild.com';
const phpSiteUrl = 'https://app-linux.stg.tai-anchorbuild.com';
const cognitoDomain = 'https://asop-au-staging.auth.ap-southeast-2.amazoncognito.com';
const cognitoClientId = '6uru606h0ms64595b4jo04o2f6';
const domain = 'https://asop-uw-login.stg-tech.tai-anchorbuild.com';

export const environment = {
  production: true,
  backendHost: backendHost,
  auApiUrl: backendHost + '/api',
  nzApiUrl: 'https://app-win-nz.stg.tai-anchorbuild.com/api',
  caApiUrl: 'https://app-dotnet-ca.stg.tai-anchorbuild.com/api',
  payaiUrl: payaiUrl,
  payAsUrl: 'https://pay-auditshield-com-au.stg.tai-anchorbuild.com',
  redirectUrl: phpSiteUrl,
  officialWebsite: 'https://www.accountancyinsurance.com.au',
  companyName: 'Accountancy Insurance',
  companyEmail: 'info@accountancyinsurance.com.au',
  companyPhone: '1300 650 758',
  country: 'AU',
  env: 'STAGING',
  cognitoUrl: 'https://sso.accountancyinsurance.com/sso-staging',
  cognitoLogoutUrl: `${cognitoDomain}/logout?client_id=${cognitoClientId}&logout_uri=${domain}/sso/logout`,
  maintenance: {
    bannerOption: {
      show: false,
      regions: []
    },
    pageOption: {
      show: false,
      regions: [] //regions: ['CA', 'AU', 'NZ']
    },
    enable: false
  },
};
