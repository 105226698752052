import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
  name: 'emailAddressTransform'
})
export class EmailAddressTransformPipe implements PipeTransform {

  constructor(private sanitizer: DomSanitizer) {
  }

  transform(value) {
    if (value) {
      // AS-6683  Transform the <emailaddress> tag..
      // To: BBB AAA  <aaa@abc.com>  -->  To: BBB AAA - shipf@shinetechsoftware.com
      const regex = /<([^<>@]+@[^<>@]+)>/g;
      const result = value.replace(regex, '- $1');
      console.log(result);
      return this.sanitizer.bypassSecurityTrustHtml(result);
    } else {
      return '';
    }
  }

}
