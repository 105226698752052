/* eslint-disable @typescript-eslint/naming-convention */
/*
  highAccessLevels: Director, UnderwriterAgencyAgent
  midAccessLevels: OperationManager, Staff, IT, Sales, OfficeManager
  lowAccessLevels: AccountManagers, PITeam, Indo, Marketing, Finance
*/

export class Permissions {
  public static AccessLevel = {
    Director: ['search-accountant-list', 'my-workbook', 'list-accout', 'edit-accout', 'accountant-summary', 'broker-accountant-summary-detail', 'paid-by-account-manager', 'paid-by-account-manager-list', 'paid-by-am-report-pmr', 'paid-by-am-report-pmr-list', 'ip-address-labels', 'claim-report-by-claim-type', 'ip-address-labels'],
    IT: ['search-accountant-list', 'auth-logs', 'my-workbook', 'list-accout', 'edit-accout', 'assign-accout', 'accountant-summary', 'broker-accountant-summary-detail', 'paid-by-account-manager', 'paid-by-account-manager-list', 'paid-by-am-report-pmr', 'paid-by-am-report-pmr-list', 'ip-address-labels', 'claim-report-by-claim-type', 'ip-address-labels'],
    PITeam: ['search-accountant-list', 'claim-report-by-claim-type'],
    AccountManagers: ['my-workbook', 'paid-by-account-manager', 'paid-by-account-manager-list', 'paid-by-am-report-pmr', 'paid-by-am-report-pmr-list', 'claim-report-by-claim-type'],
    Finance: ['search-accountant-list', 'claim-report-by-claim-type'],
    Marketing: ['search-accountant-list', 'my-workbook', 'claim-report-by-claim-type'],
    OperationManager: ['search-accountant-list', 'my-workbook', 'paid-by-account-manager', 'paid-by-account-manager-list', 'paid-by-am-report-pmr', 'paid-by-am-report-pmr-list', 'claim-report-by-claim-type'],
    Staff: ['search-accountant-list', 'my-workbook', 'claim-report-by-claim-type'],
    Sales: ['search-accountant-list', 'my-workbook', 'paid-by-account-manager', 'paid-by-account-manager-list', 'paid-by-am-report-pmr', 'paid-by-am-report-pmr-list', 'claim-report-by-claim-type'],
    OfficeManager: ['search-accountant-list', 'my-workbook', 'claim-report-by-claim-type'],
    UnderwriterAgencyAgent: ['search-accountant-list', 'paid-by-am-report-pmr', 'paid-by-am-report-pmr-list', 'claim-report-by-claim-type'],
    Debtor: []
  };
}
